.flex-container {
    padding: 2rem 10rem;
    /* padding-top: 10rem;     */
    /* flex: 1;         */
}

.logoWidth{
    width: 100%;
}

/* .btn {
    background-color: #312C71;
} */

label {
    font-size: 12pt;
}

@media (max-width: 1366px) {
}

@media (max-width:1920px) {
}

@media (max-width:520px) {
    .flex-container {
        padding: 1rem 1rem;
    }
    .logoWidth{
        width: 100%;
    }
}

input[type="file"] {
    display: none;
}

.custom-file-upload{
    margin-top: 43px;
    width: 100%;
    background-color: #E95B31;
    padding: 0.7rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #E95B31;
    text-align: center;
    font-size: 15px;
}

.custom-file-send{
    margin-top: 43px;
    width: 100%;
    background-color: #312C71;
    padding: 0.7rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid #312C71;
    text-align: center;
    font-size: 15px;
}

.custom-file-upload i{
    padding-right: 8px;
}

.box-container{
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.box-container div{
    text-align: center;
}

.box-container img{
    width: 50%;
    border-radius: 20px;
}

.input{
    border-radius: 60px;
    border: 2px solid #D0CECE;
}

.labelForm{
    color: #00015E;
    font-weight: bold;
}

.cardForm{
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 20px;
}
